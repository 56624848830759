<template>
  <b-row>
    <b-col lg="6" md="6">
      <template v-if="TokenService.getPermissions('dashboard_widget_active_profiles')">
        <most-popular-users :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent" />
      </template>
      
      <template v-if="TokenService.getPermissions('dashboard_widget_popular_topics')">
        <most-popular-topic :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent" />
      </template>
      
      <template v-if="TokenService.getPermissions('dashboard_widget_app_statistics')">
        <total-data />
      </template>
      
      <template v-if="TokenService.getPermissions('dashboard_widget_online_users')">
        <show-user-online />
      </template>
    </b-col>
    <b-col lg="6" md="6">
      <template v-if="TokenService.getPermissions('dashboard_widget_timeline')">
        <app-user-graph-by-time-dashboard @dateRange="dateRange" />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from "bootstrap-vue";
import axios from "axios";
import TotalData from "./components/TotalData.vue";
import AppUserGraphByTimeDashboard from "./components/AppUserGraphByTimeDashboard.vue";
import MostPopularTopic from "./components/MostPopularTopic.vue";
import MostPopularUsers from "./components/MostPopularUsers.vue";
import addEditDeleteGraph from "./components/addEditDeleteGraph.vue";
import showUserOnline from "./components/showUserOnline.vue";
import TokenService from "../TokenService";
export default {
  components: {
    showUserOnline,
    TotalData,
    addEditDeleteGraph,
    MostPopularTopic,
    MostPopularUsers,
    AppUserGraphByTimeDashboard,
    BRow,
    BCol,
  },
  data() {
    return {
      dashboardTable: [],
      sendDatesPropsToAllComponent: {},
      TokenService,
    };
  },

  created() {
    if (localStorage.getItem("firstlogin") == "1") {
      window.location.reload();
      localStorage.removeItem("firstlogin");
    }
  },

  methods: {
    dateRange(date) {
      this.sendDatesPropsToAllComponent = date;
    },
    GET_TABLE_DASHBOARD() {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/dashboard-table/widget", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.dashboardTable = get_response.data;
        });
    },
  },
};
</script>
