<template>
  <b-card no-body :key="sidebarkey">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">Zape Time Graph</b-card-title>
      </div>
      <div class="d-flex align-items-center">
        <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
          <b-spinner label="Loading..." />
        </span>
        <date-range-picker
          ref="picker"
          :opens="'left'"
          :locale-data="{
            direction: 'ltr',
            format: 'yyyy-mm-dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            firstDay: 0,
          }"
          :minDate="'2019-01-01 00:00:00'"
          :maxDate="'2099-01-01 00:00:00'"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dateRange"
          @update="getGraphTotalTime"
          :linkedCalendars="true"
        >
        </date-range-picker>
      </div>
    </b-card-header>
    <b-card-body style="position: relative; top: 9%">
      <vue-apex-charts
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardSubTitle,
  BCardBody,
  BCardHeader,
  BSpinner,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";

export default {
  components: {
    BCard,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
    BSpinner,
    VueApexCharts,
    flatPickr,
    DateRangePicker,
    BCardTitle,
  },

  data() {
    return {
      spinnerPlayStop: false,
      dateRange: {},
      rangePicker: ["2022-05-01", "2022-05-10"],
      sidebarkey: 0,
      Picker: "2022-05-01 to 2022-05-10",
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#d2b0ff",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series3: "#a4f8cd",
          series2: "#60f2ca",
          series1: "#2bdac7",
        },
      },
      lineAreaChartSpline: {
        series: [
          {
            name: "Minutes",
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: "straight",
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
              "100",
            ],
          },
          yaxis: {},
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: false,
          },
          colors: ["#2bdac7", "#60f2ca", "#a4f8cd"],
        },
      },
      datetime: [],
      sidebarkey: 0,
    };
  },
  created() {
    this.dateRange = {};
    this.firstload();
  },
  methods: {
    forcRender() {
      this.sidebarkey += 1;
    },
    firstload(data) {
      this.spinnerPlayStop = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/zape-cms/dashboard/seven-days/user-report",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          var time = [];
          var date = [];
          for (let index = 0; index < response.data.length; index++) {
            time.push(response.data[index].Time);
            date.push(response.data[index].date);
          }
          this.lineAreaChartSpline.series[0].data = time;
          var a = new Date();
          var b = 0;
          var resulta = a.setDate(a.getDate() + b);
          var someDate = new Date();
          var numberOfDaysToAdd = 0;
          var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
          this.dateRang = {};
          this.dateRange = {
            endDate: new Date(result),
            startDate: new Date(resulta),
          };

          this.$emit("dateRange", this.dateRange);
          this.lineAreaChartSpline.chartOptions.xaxis.categories = date;
          this.spinnerPlayStop = false;
          this.forcRender();
        });
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    getGraphTotalTime(value) {
      this.$emit("dateRange", value);
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      if (startDate == lastDate) {
        var datetime = {
          date: `${startDate}`,
          gradeId: "",
        };
      } else {
        var datetime = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
          gradeId: "",
        };
      }

      axios
        .post(
          process.env.VUE_APP_API_URL + "/zape-app/user/total-time/graph",
          datetime,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          var time = [];
          var date = [];
          for (let index = 0; index < response.data.length; index++) {
            time.push(response.data[index].Time);
            date.push(response.data[index].date);
          }
          this.lineAreaChartSpline.series[0].data = time;
          this.lineAreaChartSpline.chartOptions.xaxis.categories = date;
          this.spinnerPlayStop = false;
          this.forcRender();
        });
    },
  },
};
</script>
